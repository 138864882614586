
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Solar',

    metaInfo: {
      title: 'Solar',
      titleTemplate: 'RH Robotics Solar Cleaning Robots',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'RH Robotics, Ronghao Robot Technology, robot, automation, technology, ronghao, intelligent, solution, provider, factory, smart factory, Malaysia, south-east asia, southeast asia, asia, Indonesia, Tailand, Vietnam, Taiwan, Singapore, China, Warehouse, Smart Wearhouse, IT system, SI, System Integration, ERP, WMS, WCS, MES, simulation, Robotic technology, Intelligent manufacturing, storage, logistics, Goods To People, Software Integration, Industry revolution 4.0, industrial upgrading, Information TECHNOLOGY, Mechanical automation, centralized operations, time saving, unit loading, labor force reduction, 3 dimensional storage, streamline process, shuttle rack, AS/RS, GTP, Mini Load, Pallet Automatic Warehouse, DPS, DAS, Digital Picking System, Digital Assortment System, Conveyor, Sorter, Vertical Carrier, AGV, Arc Welding, 3d Vision Scanner, Enterprise Resource Planning, Warehouse Management System, Warehouse Control System, Manufacturing Execution System, CONSULTING, ENGINEERING, PORTFOLIO, IMPLEMENTED PROJECTS, Auxiliary lines, BROCHURE, Solar Cleaning Robots, Solar, Solar Panel, solar panel cleaning system, solar panel cleaning, claening machine' },
        { vmid: 'description', name: 'description', content: 'Ronghao (Shandong) Robot Technology Co., Ltd. was established in June 2021. Located at No. 9009, Qinglongshan Road, High-Tech Zone, Zibo City, Shandong Province, its core business is in intelligent manufacturing and industrial upgrading. Ronghao Robot Technology specializes in providing intelligent factory solutions through automation and robotic technology.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'solar',
        'solar-robots',
        'why-cleaning-robot',
        'banner-solar',
        'slogan',
        'before-footer',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
